import {RatingFilterI, RatingFilterQueryFields} from '../core/entities/rating/rating-filter';
import {RatingKinds} from "../core/entities/rating/rating";

export const ratingFilterInitialValues: RatingFilterI = {
  name: '',
  inn: '',
  catalogsIds: [],
  categoryIds: [],
  brandIds: [],
  countryIds: [],
  subCategoriesIds: [],
  active:true,
  offset: 0,
  navigation: {
    limit: 50,
    desc: false,
    offset: 0,
  },
};

export const ratingFilterFieldInitValues: RatingFilterQueryFields = {
  brandQuery: { query: '', selectedIds: [] },
  catalogsQuery: { query: '', selectedIds: [] },
  categoriesQuery: { query: '', selectedIds: [] },
  countriesQuery: { query: '', selectedIds: [] },
};
